const PUP_DISPLAY_NAME = 'Card Blockers'
const PRICE = 4.99
const TRIAL_DURATION = 14
const PUP_ID='3'

const FEATURES = [
  "Add blocking tags to your cards",
  "Add blocked tags to your cards",
  "Add milestone tags to your cards",
  "Use the tags to indicate relationships between cards",
  "Add Power-Up to unlimited boards",
  "Access to email support",
  "Invite unlimited workspace members to use the Power-Up"
]

const WORKSPACE_REPORTS_FEATURES = [
    "Access card data across all workspace boards",
    "Sort and filter by due date, last activity, members, and more",
    "Save, share, and export custom reports",
    "Update due dates directly from any workspace report",
    "Add Power-Up to unlimited boards",
    "Access email support",
    "Invite unlimited workspace members to use the Power-Up"
  ]
  

export { PUP_DISPLAY_NAME, PRICE, TRIAL_DURATION, PUP_ID, FEATURES, WORKSPACE_REPORTS_FEATURES }
