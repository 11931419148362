/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from "lodash"
import { getOrCreateTask, getTask, updateTask } from "../api/trello-api"
import { getAuthorizationStatus } from "../authorization-status/capability"
import { defaultCardButtonHeight } from "../card-button/capability"
import { type CapabilityProps } from "../types/power-up"
import { type Trello } from "../types/trello"
import { defaultCardBackSectionHeight } from "../config"
import { apiClient } from "../api/api"

export async function getCardBackSection(
  t: Trello.PowerUp.IFrame,
  props: CapabilityProps
): Promise<any> {
  const labels = await t.card("labels")
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const idCard = t.getContext().card!

  const isBlocking = labels.labels.some((label) => label.name === "blocking")
  const isBlocked = labels.labels.some((label) => label.name === "blocked")
  const isMilestone = labels.labels.some((label) => label.name === "milestone")
  const task = await getOrCreateTask(t)

  const newTask = {
    ...task,
    blocked: isBlocking ? [...task.blocked] : [],
    blockedBy: isBlocked ? [...task.blockedBy] : [],
    milestone: isMilestone ? [...task.milestone] : []
  }

  if (!isEqual(task, newTask)) {
    await updateTask(t, newTask)
  }

  if (!isBlocking) {
    if (task.blocked?.length) {
      task.blocked?.forEach(async (id) => {
        const targetTask = await getTask(t, id)
        if (targetTask) {
          const newTargetTask = {
            ...targetTask,
            blockedBy: targetTask.blockedBy?.filter((id) => id !== idCard)
          }
          await updateTask(t, newTargetTask, id)
        }
      })
    }
  }

  if (!isBlocked) {
    if (task.blockedBy?.length) {
      task.blockedBy?.forEach(async (id) => {
        const targetTask = await getTask(t, id)
        if (targetTask) {
          const newTargetTask = {
            ...targetTask,
            blocked: targetTask.blocked?.filter((id) => id !== idCard)
          }
          await updateTask(t, newTargetTask, id)
        }
      })
    }
  }

  if (!isBlocked && !isBlocking && !isMilestone) {
    return
  }
  const isAuthorized = (await getAuthorizationStatus(t)).authorized

  if (!isAuthorized) return

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { status } = await apiClient.getCurrentSubscription(
    t,
    t.getContext().organization!
  )

  if (status === "deleted" || status === "past_due") return

  return {
    title: "Card Blockers",
    icon: props.baseUrl + props.logo.dark,
    content: {
      type: "iframe",
      url: t.signUrl("./card-back-section.html"),
      height: defaultCardBackSectionHeight
    },
    action: {
      text: "Manage Labels",
      callback: async (t: Trello.PowerUp.IFrame) => {
        await t.popup({
          title: `Card Blockers`,
          url: t.signUrl("./card-button.html"),
          height: defaultCardButtonHeight
        })
      }
    }
  }
}
