import { type Trello } from '../types/trello'
import { PUP_DISPLAY_NAME } from '../constants'

export async function getOnEnable (
  t: Trello.PowerUp.IFrame,
): Promise<void> {
  await t.alert({
    message: `${PUP_DISPLAY_NAME} Power-Up installed!`,
    display: 'info',
    duration: 10
  })
}
