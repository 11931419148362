import { CapabilityProps, type Task } from './types/power-up'

export const defaultTask: Task = {
  blocked: [],
  blockedBy: [],
  milestone: []
}

export const labelColors = {
  yellow: 'bg-[#F5CD47] text-[#533F04]',
  yellow_light: "bg-[#FFF7D6] text-[#7F5F01]",
  yellow_dark: 'bg-[#946F00] text-white',
  red: 'bg-[#F87168] text-[#5D1F1A]',
  red_light: "bg-[#FFECEB] text-[#AE2E24]",
  red_dark: 'bg-[#C9372C] text-white',
  orange: 'bg-[#FEA362] text=[#702E00]',
  orange_dark: 'bg-[#C25100] text-white',
  orange_light: "bg=[#FEDEC8] text-[#A54800]",
  blue: 'bg-[#579DFF] text-[#09326C]',
  blue_dark: 'bg-[#0C66E4] text-white',
  blue_light: "bg-[#CCE0FF] text-[#0055CC]",
  purple: 'bg-[#9F8FEF] hover:bg-[#B8ACF6] text-[#x352C63]',
  purple_dark: 'bg-[#6E5DC6] text-white',
  purple_light: "bg-[#DFD8FD] text-[#5E4DB2]",
  sky: 'bg-[#6CC3E0] text-[#164555]',
  sky_dark: "bg-[#227D9B] text-white",
  sky_light: "bg-[#C6EDFB] text=[#206A83]",
  lime: 'bg-[#94C748] text-[#37471F]',
  lime_dark: "bg-[#5B7F24] text-white",
  lime_light: "bg-[#D3F1A7] text-[#4C6B1F]",
  pink: 'bg-[#FDD0EC] text-[#50253F]',
  pink_dark: "bg-[#AE4787] text-white",
  pink_light: "bg-[#FDD0EC] text-[#943D73]",
  black: 'bg-[#8590A2] text-[#091E42]',
  black_dark: "bg-[#626F86] text-white",
  black_light: "bg-[#DCDFE4] text-[#44546F]",
  green: 'bg-[#4BCE97] text-[#164B35]',
  green_dark: 'bg-[#1F845A] text-white',
  green_light: 'bg-[#BAF3DB] text-[#216E4E]',
}

export const defaultCardBackSectionHeight = 300

export const labelColorsByTarget = {
  blocking: 'red',
  blocked: 'yellow',
  milestone: 'blue'
}

export const CAPABILITY_PROPS: CapabilityProps = {
  baseUrl: window.location.href.replace(/\/$/, ''),
  logo: {
    dark: '/static/logo.png',
    light: '/static/logo.png'
  },
  icon: {
    dark: '/static/icon.svg',
    light: '/static/icon.svg'
  },
  authorizeIcon: {
    dark: '/static/authorize-icon.svg',
    light: '/static/authorize-icon.svg'
  },
  upgradeIcon: {
    dark: '/static/upgrade-icon.svg',
    light: '/static/upgrade-icon.svg'
  }

}
