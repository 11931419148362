/* eslint-disable @typescript-eslint/no-explicit-any */
import { Task } from '../types/power-up'
import { defaultTask } from '../config'
import { Trello } from '../types/trello'

export async function updateTask (
    t: Trello.PowerUp.IFrame,
    task: Partial<Task>,
    cardId?: string
  ): Promise<void> {
    const tsk = await t.get(cardId ?? 'card', 'shared', 'task')
  
    let originalTask: any
  
    if (tsk) {
      originalTask = JSON.parse(tsk)
    } else {
      originalTask = defaultTask
    }
  
    const newTask = { ...originalTask, ...task }
  
    await t.set(cardId ?? 'card', 'shared', 'task', JSON.stringify(newTask))
  }
  
  export async function getTask (
    t: Trello.PowerUp.IFrame,
    cardId?: string
  ): Promise<Task | undefined> {
    const tsk = await t.get(cardId ?? 'card', 'shared', 'task')
  
    if (!tsk) {
      return
    } else {
      const newTask = JSON.parse(tsk)
      return {
        ...newTask,
        // there might be an old-prop that is a milestone boolean, we need to check for that
        milestone: typeof newTask?.milestone === 'boolean' ? [] : newTask?.milestone ?? []
      }
    }
  }

  export async function getOrCreateTask (
    t: Trello.PowerUp.IFrame,
    cardId?: string
  ): Promise<Task> {
    const tsk = await t.get(cardId ?? 'card', 'shared', 'task')
  
    if (!tsk) {
      return defaultTask
    } else {
      const newTask = JSON.parse(tsk)
      return {
        ...newTask,
        // there might be an old-prop that is a milestone boolean, we need to check for that
        milestone: typeof newTask?.milestone === 'boolean' ? [] : newTask?.milestone ?? []
      }

    }
  }
  