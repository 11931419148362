import { Trello } from "../types/trello";
import { PUP_DISPLAY_NAME } from "../constants";
import { getAuthorizationStatus } from "../authorization-status/capability";

export async function getShowSettings (
    t: Trello.PowerUp.IFrame,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const isAuthorized = (await getAuthorizationStatus(t as Trello.PowerUp.IFrame)).authorized
    if (isAuthorized) {
        return t.modal({
        title: `${PUP_DISPLAY_NAME} | Settings`,
        url: './get-premium.html',
        fullscreen: true,
        })
    }
  }