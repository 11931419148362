import { type Trello } from '../types/trello'
import { type CapabilityProps } from '../types/power-up'
import { getAuthorizationStatus } from '../authorization-status/capability'
import { getShowAuthorization } from '../show-authorization/capability'
import { PUP_DISPLAY_NAME } from '../constants'
import { apiClient } from "../api/api"

export const defaultCardButtonHeight = 130
const buttonText = 'Card Blockers'

export async function getCardButton (
  t: Trello.PowerUp.IFrame,
  props: CapabilityProps
): Promise<Trello.PowerUp.CardButton[]> {
  const isAuthorized = (await getAuthorizationStatus(t)).authorized

  if (!isAuthorized) {
    return [
      {
        icon: props.baseUrl + props.logo.dark,
        text: buttonText,
        callback: (tc: Trello.PowerUp.IFrame) => 
          getShowAuthorization(tc),
      }
    ]
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { status } = await apiClient.getCurrentSubscription(t, t.getContext().organization!)

  if (status === 'active' || status === 'trialing') {
    return [
      {
        icon: props.baseUrl + props.logo.dark,
        text: buttonText,
        callback: (tc: Trello.PowerUp.IFrame) =>
          tc.popup({
            title: buttonText,
            url: tc.signUrl('./card-button.html'),
            height: defaultCardButtonHeight
          })
      }
    ]
  }

  return [
    {
      icon: props.baseUrl + props.icon.dark,
      text: buttonText,
      callback: (tc: Trello.PowerUp.IFrame) => {
        return tc.modal({
          title: `${PUP_DISPLAY_NAME}`,
          url: './get-premium.html',
          fullscreen: true,
        })
      },
      condition: 'edit'
    }
  ]
  }
