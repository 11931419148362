import { ApiClient } from "@dasher-react/components"
import { PUP_ID } from "../constants"

const pupAPiUrl = process.env.PUP_API_URL


if (!pupAPiUrl) {
  throw new Error("PUP_API_URL is required")
}

export const apiClient =  new ApiClient(pupAPiUrl, PUP_ID)